import React, { useEffect, useRef, useState } from "react";
import { Aside } from "./Aside/Aside";
import Friends from "./Friends/Friends";
import Map from "./Map/Map";
import NavbarHome from "../home/NavbarNew/NavbarHome";
import "../../assets/styles/Profile/Profile.css";
import { MdApartment, MdLocalPhone, MdOutlineLanguage } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import ModalImage from "react-modal-image";
import { FaEdit, FaShareSquare } from "react-icons/fa";

import PopupShare from "./PopupShare";
import FootNav from "../User_Profile/Footer/Footer-nav/FootNav";
import { AiFillCamera } from "react-icons/ai";
import {
  Server2,
  UserProfileUpdate,
} from "../../redux/actions/auth/auth.actions";
import axios from "axios";
import { IoMdCamera } from "react-icons/io";

import GalleryProfile from "../../components/GalleryProfile";
import ProfileImage from "./ProfileImage";
import CoverImage from "./CoverImage";

const MyProfile = () => {
  const userData = useSelector((state) => state?.auth?.user?.user);
  const [NewData, setNewData] = useState();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(UserProfileUpdate(userData?._id));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [isOpen, setIsOpen] = useState(false);
  const [isHours, setIsHours] = useState(1);
  const togglePopup = () => {
    setIsOpen(!isOpen);
  };
  const [isOpenGallary, setIsOpenGallary] = useState(false);
  // Image Selection Start
  const [ImageProfile, setImageProfile] = useState("");
  const [ImageCover, setImageCover] = useState("");

  const [ProfilePicPopup, setProfilePicPopup] = useState(false);
  const [CoverPicPopup, setCoverPicPopup] = useState(false);

  const [ProfilePicView, setProfilePicView] = useState(false);
  const [CoverPicView, setCoverPicView] = useState(false);

  const inputFileProfile = useRef(null);
  const inputFileCover = useRef(null);
  
  useEffect(() => {
    if (!CoverPicPopup) {
      // Reset input value when CoverPicPopup is false
      inputFileCover.current.value = null;
    }
    if (!ProfilePicPopup) {
      // Reset input value when ProfilePicPopup is false
      inputFileProfile.current.value = null;
    }
  }, [CoverPicPopup , ProfilePicPopup]);
  // to open file explore
  const openInputFileProfile = (e) => {
    inputFileProfile.current.click();
  };
  const openInputFileCover = (e) => {
    inputFileCover.current.click();
  };

  // to display image
  const handleImageChangeProfile = (e) => {
    inputFileProfile.current.click();
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setImageProfile(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setProfilePicPopup(true);
    }
  };

  // to display cover
  const handleImageChangeCover = (e) => {
    inputFileCover.current.click();
    if (e.target.files && e.target.files[0]) {
      let reader = new FileReader();
      reader.onload = function (e) {
        setImageCover(e.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setCoverPicPopup(true);
    }
  };

  const handleModelClose = (isChanged) => {
    setCoverPicView(false)
    setProfilePicView(false)
    console.log(isChanged,"picture changed")
    if(isChanged)
      console.log("first")
      // updateNew()
  }

  const [GallaryImg, setGallaryImg] = useState([]);
  const [GallaryRender, setGallaryRender] = useState(false);

  useEffect(() => {
    let data = {
      user: userData._id,
    };
    let config = {
      method: "post",
      url: Server2 + "/users/get-gallery-images",
      headers: { "Content-Type": "application/json" },
      data: data,
    };
    axios(config)
      .then((response) => {
        setGallaryImg(response.data);
      })
      .catch((err) => {
        console.log(err, "error");
      });
  }, [isOpenGallary, GallaryRender]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    fetch(
      Server2 + `/users/userprofile?id=${userData._id}&user=${userData._id}`,
      {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      }
    ).then(async (response) => {
      const data = await response.json();
      setNewData(data.user);
    });
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const Url = `https://www.ibouge.com/BusinessProfile/_id=${userData?._id}`;
  return (
    <div className="bg-white">
      <NavbarHome />
      <div className="px-3">

        <div className="position-relative">
          {/* Cover Photo */}
          <div className="img-cover rounded-3">
            <div className="d-flex" style={{ position: "absolute", right: "15px", top: "10px" }}>
              <div style={{
                  padding: "5px",
                  cursor: "pointer",
                  borderRadius: "4px",
                  boxShadow: "0px 10px 50px rgba(21, 48, 89, 0.3)",
                  backgroundColor: "white",
                }}
                onClick={openInputFileCover}
                onChange={handleImageChangeCover}
              >
                <AiFillCamera size={22} color={"black"} />
                <span className="ms-1">Edit Cover Photo</span>
              </div>
            </div>
            <div>
              <img
                className="cover"
                src={userData?.cover_pic
                  ? userData?.cover_pic
                  : "/img/swiming105.png"}
                alt='user`s coverImage'
                style={{cursor: 'pointer'}}
                onClick={(e) => {
                  setCoverPicView(true)
                  setImageCover(userData?.cover_pic ? userData?.cover_pic : "/img/swiming105.png");
                }}
              />
            </div>
            <input
              type="file"
              id="file"
              className="photouser"
              ref={inputFileCover}
              onClick={openInputFileCover}
              onChange={handleImageChangeCover}
              accept="image/png, image/jpeg"
              style={{ display: "none" }}
            />
          </div>
          {/* Profile Picture and Name */}
          <div className="profile row d-flex px-0 justify-content-center justify-content-md-start align-items-center">

            <div className="col-lg-2 col-md-3 col-sm-12 order-md-1 order-2 text-center text-md-end">
              <div className="position-relative">
                <div className="d-flex justify-content-center">
                  <img
                    className="img-admin img-fluid ms-4"
                    src={userData?.profile_pic ? userData?.profile_pic : "/img/upload-photo.png"}
                    alt='User`s ProfileImage'
                    style={{cursor: 'pointer', minWidth: 160}}
                    onClick={(e) => {
                      setProfilePicView(true)
                      setImageProfile(userData?.profile_pic ? userData?.profile_pic : "/img/upload-photo.png");
                    }}
                  />
                  <input
                    type="file"
                    id="file"
                    className="rounded-circle photouser"
                    ref={inputFileProfile}
                    onClick={openInputFileProfile}
                    accept="image/png, image/jpeg"
                    onChange={handleImageChangeProfile}
                    style={{ display: "none" }}
                  />
                  <IoMdCamera
                    color="white"
                    style={{
                      backgroundColor: "#9f47a0",
                      position: "relative",
                      marginTop: "100px",
                      left: "-30px",
                      cursor: "pointer",
                      border: "2px solid white",
                      minWidth: "38px"
                    }}
                    size={38}
                    className="p-2 rounded-5 icon-upload"
                    onClick={openInputFileProfile}
                    onChange={handleImageChangeProfile}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-sm-12 order-md-2 order-1 pt-0 ">
              <p
                className="fw-bold fs-3 text-lg-start text-md-start text-center text-white"
                style={{ textShadow: "1px 1px #000000" }}
              >
                {userData.fname} {userData.lname}
              </p>
            </div>
            <div className="col-lg-8 col-md-8 order-lg-2 order-md-2 "></div>
          </div>

          {/* Share Profile Button */}
          <div className="row add-chat">
            <div className="d-flex justify-content-end offset-8 col-4 pb-3">
              {userData?.type === true ? (
                <button
                  type="button"
                  className=" border-0 rounded-2 chat-btn ms-2 p-2 fw-bold"
                  style={{ width: "40%" }}
                  data-bs-toggle="button"
                  onClick={togglePopup}
                >
                  <FaShareSquare className="btn-icons me-2 fw-bold" />
                  <span className="d-none d-lg-inline d-xl-inline">
                    Share Profile
                  </span>
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>

        {isOpen && (
          <PopupShare
            isOpen={isOpen}
            handleClose={() => setIsOpen(!isOpen)}
            Url={Url}
            isHours={isHours}
            setIsHours={(e) => setIsHours(e)}
          />
        )}
        {isOpenGallary && (
          <PopupShare
            isOpen={isOpen}
            handleClose={() => setIsOpenGallary(!isOpenGallary)}
          />
        )}

        <div className="row">
          <div className="col-lg-8 col-md-12 col-sm-12 ">
            {userData.type === false ? (
              <>
                <div
                  className="text-xl-start text-lg-start text-center mx-2 pt-1 mt-2 pb-2"
                  style={{ height: "200px", overflow: "auto" }}
                >
                  <p className="fw-bold text-dark">About Me</p>
                  <p
                    className=""
                    style={{
                      fontSize: "12px",
                      font: "Roboto",
                      fontWeight: "300",
                    }}
                  >
                    {userData.profile.about_me}
                  </p>
                  <p className="fw-bold text-dark">Summary</p>
                  <div className="">
                    <p className="fw-bold text-dark">
                      Interests: &nbsp;
                      {userData.profile.interests.map((inter, index) => {
                        const last = userData.profile.interests.slice(-1)[0];
                        return (
                          <span
                            key={index}
                            className=""
                            style={{
                              fontSize: "12px",
                              font: "Roboto",
                              fontWeight: "300",
                            }}
                          >
                            {inter}
                            {inter === last ? null : ","} &nbsp;
                          </span>
                        );
                      })}
                    </p>
                    <p className="fw-bold text-dark">
                      Languages: &nbsp;
                      {userData.profile.languages.map((item, index) => {
                        const last = userData.profile.languages.slice(-1)[0];
                        return (
                          <span
                            key={index}
                            style={{
                              fontSize: "12px",
                              font: "Roboto",
                              fontWeight: "300",
                            }}
                          >
                            {item}
                            {item === last ? null : ","} &nbsp;
                          </span>
                        );
                      })}
                    </p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div
                  style={
                    {
                      // height: "87px",
                      // overflow: "auto",
                      // marginBottom: "20px",
                    }
                  }
                >
                  <span
                    className="fw-bold text-dark ps-2"
                    style={{ fontSize: "16px" }}
                  >
                    Business Summary
                  </span>
                  <p
                    className="text-dark ps-2 mt-1"
                    style={{ fontSize: "12px", lineHeight: "18px" }}
                  >
                    {userData.business_info.business_summary}
                  </p>
                </div>
                {/* Business Offers */}
                <div className="border1"></div>
                <div className="rounded-3 shadow  px-3 py-3 mb-3">
                  {/* Contacts */}
                  <div className="row text-dark">
                    <div className="pt-2 pe-xxl-4 pe-xl-3 pe-lg-2">
                      <span className="fw-bold fs-6">Business Offer</span>
                      <p
                        className=""
                        style={{
                          minHeight: "30px",
                          maxHeight: "fit-content",
                          // overflow: "auto",
                          marginBottom: "5px",
                        }}
                      >
                        {userData?.business_info?.about_company.map(
                          (about, Index) => {
                            const last =
                              userData?.business_info?.about_company.slice(
                                -1
                              )[0];
                            return (
                              <span
                                key={Index}
                                className=""
                                style={{
                                  fontSize: "12px",
                                  font: "Roboto",
                                  fontWeight: "300",
                                }}
                              >
                                {about}
                                {about === last ? null : ","} &nbsp;
                              </span>
                            );
                          }
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="border1"></div>
                <div className="rounded-3 shadow px-3 py-3">
                  {/* Contacts */}
                  <div className="row text-dark mb-2 text-size w-100">
                    <div className="pe-xxl-4 pe-xl-3 pe-lg-2 col-6">
                      <span className="fw-bold text-heading-size d-flex align-items-center">
                        Opening Hours
                        <FaEdit
                          color="#6148a1"
                          className="my-auto"
                          size={21}
                          style={{ marginLeft: "5%", cursor: "pointer" }}
                          onClick={() => {
                            setIsOpen(true);
                            setIsHours(2);
                          }}
                        />
                      </span>
                      <table>
                        {/* <tbody style={{fontSize:"12px"}}> */}
                        <tbody>
                          <tr>
                            <td>Monday</td>
                            <td>:&nbsp;</td>
                            {NewData?.business_info?.business_hours[0]?.Monday
                              ?.isOff === true ? (
                              <td>Closed</td>
                            ) : (
                              <td>
                                {NewData?.business_info?.business_hours[0]
                                  ?.Monday?.opening
                                  ? NewData?.business_info?.business_hours[0]
                                      ?.Monday?.opening
                                  : "09:00 AM"}
                                &nbsp;-&nbsp;
                                {NewData?.business_info?.business_hours[0]
                                  ?.Monday?.closing
                                  ? NewData?.business_info?.business_hours[0]
                                      ?.Monday?.closing
                                  : "09:00 PM"}
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>Tuesday</td>
                            <td>:&nbsp;</td>
                            {NewData?.business_info?.business_hours[1]?.Tuesday
                              ?.isOff === true ? (
                              <td>Closed</td>
                            ) : (
                              <td>
                                {NewData?.business_info?.business_hours[1]
                                  ?.Tuesday?.opening
                                  ? NewData?.business_info?.business_hours[1]
                                      ?.Tuesday?.opening
                                  : "09:00 AM"}
                                &nbsp;-&nbsp;
                                {NewData?.business_info?.business_hours[1]
                                  ?.Tuesday?.closing
                                  ? NewData?.business_info?.business_hours[1]
                                      ?.Tuesday?.closing
                                  : "09:00 PM"}
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>Wednesday&nbsp;</td>
                            <td>:&nbsp;</td>
                            {NewData?.business_info?.business_hours[2]
                              ?.Wednesday?.isOff === true ? (
                              <td>Closed</td>
                            ) : (
                              <td>
                                {NewData?.business_info?.business_hours[2]
                                  ?.Wednesday?.opening
                                  ? NewData?.business_info?.business_hours[2]
                                      ?.Wednesday?.opening
                                  : "09:00 AM"}
                                &nbsp;-&nbsp;
                                {NewData?.business_info?.business_hours[2]
                                  ?.Wednesday?.closing
                                  ? NewData?.business_info?.business_hours[2]
                                      ?.Wednesday?.closing
                                  : "09:00 PM"}
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>Thursday</td>
                            <td>:&nbsp;</td>
                            {NewData?.business_info?.business_hours[3]?.Thursday
                              ?.isOff === true ? (
                              <td>Closed</td>
                            ) : (
                              <td>
                                {NewData?.business_info?.business_hours[3]
                                  ?.Thursday?.opening
                                  ? NewData?.business_info?.business_hours[3]
                                      ?.Thursday?.opening
                                  : "09:00 AM"}
                                &nbsp;-&nbsp;
                                {NewData?.business_info?.business_hours[3]
                                  ?.Thursday?.closing
                                  ? NewData?.business_info?.business_hours[3]
                                      ?.Thursday?.closing
                                  : "09:00 PM"}
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>Friday</td>
                            <td>:&nbsp;</td>
                            {NewData?.business_info?.business_hours[4]?.Friday
                              ?.isOff === true ? (
                              <td>Closed</td>
                            ) : (
                              <td>
                                {NewData?.business_info?.business_hours[4]
                                  ?.Friday?.opening
                                  ? NewData?.business_info?.business_hours[4]
                                      ?.Friday?.opening
                                  : "09:00 AM"}
                                &nbsp;-&nbsp;
                                {NewData?.business_info?.business_hours[4]
                                  ?.Friday?.closing
                                  ? NewData?.business_info?.business_hours[4]
                                      ?.Friday?.closing
                                  : "09:00 PM"}
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>Saturday</td>
                            <td>:&nbsp;</td>
                            {NewData?.business_info?.business_hours[5]?.Saturday
                              ?.isOff === true ? (
                              <td>Closed</td>
                            ) : (
                              <td>
                                {NewData?.business_info?.business_hours[5]
                                  ?.Saturday?.opening
                                  ? NewData?.business_info?.business_hours[5]
                                      ?.Saturday?.opening
                                  : "09:00 AM"}
                                &nbsp;-&nbsp;
                                {NewData?.business_info?.business_hours[5]
                                  ?.Saturday?.closing
                                  ? NewData?.business_info?.business_hours[5]
                                      ?.Saturday?.closing
                                  : "09:00 PM"}
                              </td>
                            )}
                          </tr>
                          <tr>
                            <td>Sunday</td>
                            <td>:&nbsp;</td>
                            {NewData?.business_info?.business_hours[6]?.Sunday
                              ?.isOff === true ? (
                              <td>Closed</td>
                            ) : (
                              <td>
                                {NewData?.business_info?.business_hours[6]
                                  ?.Sunday?.opening
                                  ? NewData?.business_info?.business_hours[6]
                                      ?.Sunday?.opening
                                  : "09:00 AM"}
                                &nbsp;-&nbsp;
                                {NewData?.business_info?.business_hours[6]
                                  ?.Sunday?.closing
                                  ? NewData?.business_info?.business_hours[6]
                                      ?.Sunday?.closing
                                  : "09:00 PM"}
                              </td>
                            )}
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="ps-xxl-5 ps-xl-4 ps-lg-3 col-6">
                      <span className="fw-bold pb-2 text-heading-size">
                        About Business
                      </span>
                      <div className="d-flex align-items-center about-b-divs pb-1 text-nowrap">
                        <MdLocalPhone
                          className="me-2 icon-size"
                          style={{ minWidth: "20px" }}
                          color="#6599AF"
                        />
                        <span className="fw-bold text-size">
                          Business Contact:
                        </span>
                        {/* <p className="bg-success">&nbsp; +{userData.business_info.phone_number}</p> */}
                        <span className="p-text-size">
                          &nbsp;+{userData.business_info.phone_number}
                        </span>
                      </div>
                      <div className="d-flex align-items-center about-b-divs pb-1 text-nowrap">
                        <MdApartment
                          className="me-2 icon-size"
                          style={{ minWidth: "20px" }}
                          color="#6599AF"
                        />
                        <span className="fw-bold text-size">
                          Business Category:
                        </span>
                        {/* <p className="F-text-size">&nbsp; {userData.business_category}</p> */}
                        <span className="F-text-size">
                          &nbsp;{userData.business_category}
                        </span>
                      </div>
                      <div className="d-flex align-items-center about-b-divs pb-1 text-nowrap">
                        <MdOutlineLanguage
                          className="me-2 icon-size"
                          style={{ minWidth: "20px" }}
                          color="#6599AF"
                        />
                        <span className="fw-bold text-size">Website URL:</span>
                        {userData.business_info.address !== null ? (
                          <a
                            href={`https://${userData.business_info.address}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="text-decoration-none ms-2 adress-size"
                            style={{
                              color: "#6148a1",
                            }}
                          >
                            {userData?.business_info?.address}
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  {/* Gallary */}
                  <GalleryProfile
                    GallaryImg={GallaryImg}
                    setIsOpenGallary={(e) => setIsOpenGallary(e)}
                    setGallaryRender={(e) => setGallaryRender(e)}
                  />
                  {/* ^ Gallary */}
                </div>
              </>
            )}
            {userData.type === false ? (
              <div className="border rounded-3 shadow mt-3">
                <div className="border1"></div>
                <div className="py-3 px-2">
                  <GalleryProfile
                    GallaryImg={GallaryImg}
                    setIsOpenGallary={(e) => setIsOpenGallary(e)}
                    setGallaryRender={(e) => setGallaryRender(e)}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {ProfilePicPopup === true ? (
              <ProfileImage
                src={ImageProfile}
                Full={inputFileProfile.current.files[0]}
                handleClose={() => setProfilePicPopup(false)}
                type={"profile"}
              />
            ) : null}
            {CoverPicPopup === true ? (
              <ProfileImage
                src={ImageCover}
                Full={inputFileCover.current.files[0]}
                handleClose={() => setCoverPicPopup(false)}
                type={"Cover"}
              />
            ) : null}

            {ProfilePicView === true ? (
              <CoverImage
                style={{zIndex: "2000"}}
                src={ImageProfile}
                handleClose={(isChanged) => handleModelClose(isChanged)}
                type={"profile"}
                user={userData._id}
              />
            ) : null}
            {CoverPicView === true ? (
              <CoverImage
                style={{zIndex: "2000"}}
                src={ImageCover}
                handleClose={(isChanged) => handleModelClose(isChanged)}
                type={"Cover"}
                user={userData._id}
              />
            ) : null}
            <Friends /> {/* < = Friends Component */}
            {/* Map Start */}
            <div className="" style={{ marginTop: "15px" }}>
              <Map />
            </div>
            {/* ^ Map End */}
          </div>
          <div className="col-lg-4 col-md-12 col-sm-12">
            <Aside />
            {/* ^ New Post Div */}
          </div>
          {/* ^ Col-4 */}
        </div>
      </div>
      <FootNav />
    </div>
  );
};

export default MyProfile;
